import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import omit from 'lodash/omit';

import {
  BLOG_ARTICLE_TYPE,
  BLOG_RELATED_ARTICLES_MAX
} from '../constants/index';
import * as types from './constants';
import * as httpClient from '../utils/httpClient';
import { parseBlogParams } from '../utils/urlHelpers/blog';
import { parseSearchParams as parseEnginesSearchParams } from '../utils/urlHelpers/engines';
import { parseBoatDetailParams } from '../utils/urlHelpers/boat';
import { getConfig } from '../config/portal';
import { getHttpRequest, getUserAgent } from '../server/middleware/services';
import {
  getConfigParamForLanguage,
  getCurrentLocale,
  getCurrentCurrency,
  getContentLocale
} from '../utils/language';
import { asString } from '../utils';
import { parseEngineDetailParams } from '../utils/urlHelpers/engine';
import {
  getDestinationPath,
  getMappedValues,
  mapRedirectResultsToValues
} from '../utils/urlHelpers/redirects';
import { buildRelatedListingParamsFromListing } from '../utils/api/relatedListings';
import { matchPath } from 'react-router';

import { getRouteConstantsFromI18n } from '../tppServices/translations/constants';
import { parseSearchParams } from '../utils/urlHelpers/boats';
import { hasAdvertisingConsent } from '../utils/cookies';
import { AD_PAGE_KEY } from '../utils/ads/adsUtils';
import { detectDevice, logOnserver } from '../tppServices/crossEnvHelpers';

import { getBoatsQuery, parseBoatDataError } from '../utils/requestHelpers';
import { getBoatDetails } from '../requests/boatDetailsRequest';
import { getMultiAds } from '../requests/multi-ads';
import { fetchBoats } from '../requests/boatsRequest';
import { addUUIDToRecords } from './utils';

const getDataFailure = (err, statusCode = 500) => ({
  type: types.GET_DATA_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false,
  data: []
});

const postDataFailure = (err, statusCode) => ({
  type: types.POST_DATA_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false
});

const getDataSuccess = (data, statusCode) => {
  return {
    type: types.GET_DATA_SUCCESS,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of things',
    isWorking: false,
    data: data
  };
};

const postDataSuccess = (statusCode) => {
  return {
    type: types.POST_DATA_SUCCESS,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of things',
    isWorking: false
  };
};

const postAdsSuccess = (data, statusCode) => {
  return {
    type: types.SET_ADS_DATA,
    success: true,
    errors: false,
    statusCode: statusCode,
    message: 'got response of ads',
    isWorking: false,
    data: data
  };
};

const getFacetSuccess = (data) => {
  return {
    type: types.GET_FACET_SUCCESS,
    data: data
  };
};

const getDataRequest = (params) => ({
  type: types.GET_DATA_REQUEST,
  params: params
});

const postDataRequest = () => ({
  type: types.POST_DATA_REQUEST
});

const getFacetRequest = (params) => ({
  type: types.GET_FACET_REQUEST,
  params: params
});

const getStaticRoutingSuccess = (data, statusCode) => ({
  type: types.GET_STATIC_ROUTING_SUCCESS,
  success: true,
  errors: false,
  statusCode: statusCode,
  message: 'Got response of Default Static Routing',
  isWorking: false,
  data: data
});

const getStaticRoutingFailure = (err, statusCode = 500) => ({
  type: types.GET_STATIC_ROUTING_FAILURE,
  success: false,
  errors: true,
  statusCode: statusCode,
  message: err,
  isWorking: false,
  data: []
});

const setStaticRoutingMatch = (data) => ({
  type: types.SET_STATIC_ROUTING_MATCH,
  data: data
});

const setUserAgent = (userAgent) => ({
  type: types.SET_USER_AGENT,
  data: userAgent
});

export const setSRPCurrency = (currency) => ({
  type: types.SET_SRP_CURRENCY,
  data: currency
});

const _shouldGetData = () => {
  let lastLocation;
  return (location) => {
    //assume server side render
    if (!lastLocation) {
      lastLocation = location;
      return false;
    }
    let getData = !isEqual(location, lastLocation);
    lastLocation = location;
    return getData;
  };
};

export const shouldGetData = _shouldGetData();

const getSortParam = (value) => ({
  type: types.GET_SORT_PARAM,
  sortBy: value
});

export const getSortByParam = (value) => (dispatch) => {
  return dispatch(getSortParam(value));
};

export const getData = (
  url,
  cookies,
  otherParams,
  pageContext,
  parseParamFunction = parseSearchParams,
  pageKey = AD_PAGE_KEY.SEARCH_RESULTS
) => async (dispatch, getState, http) => {
  const config = getConfig();
  let urlParams = parseParamFunction(url, otherParams?.ownerDetails);
  const abContext = pageContext?.abTestContext;
  const requestContext = pageContext?.requestContext;
  const device = detectDevice(requestContext);
  const hasTargetedAdsConsent = hasAdvertisingConsent(cookies || {});
  const boatsQueryParams = getBoatsQuery(config, urlParams, otherParams, cookies, abContext, device, hasTargetedAdsConsent, pageKey);
  dispatch(getDataRequest(boatsQueryParams));
  const { boatsResult, error } = await fetchBoats(boatsQueryParams, requestContext, http);
  if (boatsResult) {
    addUUIDToRecords(boatsResult);
    logOnserver(JSON.stringify({...boatsResult.adsData, tag: 'serveMultiAds'}), !!otherParams?.testId);
    dispatch(getDataSuccess(boatsResult));
  }
  if (error) {
    const errorMessage = asString(error.response?.data);
    const statusCode = error?.response?.status || error?.statusCode || 500;
    dispatch(getDataFailure(errorMessage, statusCode));
  }
};

export const getEnginesData =
  (url, cookies, otherParams) => async (dispatch, getState, http) => {
    let urlParams;
    if (otherParams && otherParams.ownerDetails) {
      urlParams = parseEnginesSearchParams(url, otherParams.ownerDetails);
    } else {
      urlParams = parseEnginesSearchParams(url);
    }

    if (urlParams.category) {
      urlParams.category =
        urlParams.category === 'outboard'
          ? 'outboard,outboard-4s,outboard-2s'
          : urlParams.category;
    }

    let params = {
      page: 1,
      pageSize: 28,
      enableSponsoredSearch: true,
      locale: getCurrentLocale(true),
      ...otherParams,
      ...urlParams
    };

    dispatch(getDataRequest(params));
    const apiClient = http || httpClient.getHttpClient();

    try {
      const response = await apiClient.get('/search/engine', { params });
      const data = response.data;
      data.facets.makeModel = data.facets.makeModel.filter(make => make.value && make.value.length > 1 && !/^[^a-zA-Z0-9]+$/.test(make.value));
      return dispatch(getDataSuccess(data));
    } catch (err) {
      const errorMessage = get(err, 'response.data', '').toString();
      const statusCode = err?.response?.status || err?.statusCode || 500;
      dispatch(getDataFailure(errorMessage, statusCode));
    }
  };

export const getNextPreviousEnginesData = (urlParams) => {
  const currency = get(getConfig().currency, 'abbr');

  if (urlParams.category) {
    urlParams.category =
      urlParams.category === 'outboard'
        ? 'outboard,outboard-4s,outboard-2s'
        : urlParams.category;
  }

  const search = '/search/engine';
  const params = {
    pageSize: 28,
    page: 1,
    currency: currency,
    ...urlParams
  };
  return httpClient.getHttpClient().get(search, { params });
};

export const getNextPreviousData = (urlParams) => {
  const search = '/search/boat';
  const params = {
    pageSize: get(getConfig(), 'pages.details.search.pagination.pageSize', 28),
    page: 1,
    facets: ['fuelType'].join(','),
    fields: ['id', 'make', 'model', 'year', 'portalLink'].join(','),
    useMultiFacetedFacets: true,
    ...urlParams
  };
  return httpClient.getHttpClient().get(search, { params });
};

export const getFacets = (url, otherParams) => async (dispatch) => {
  let search = '/search/boat';
  let urlParams = parseSearchParams(url);
  let params = {
    ...otherParams,
    ...urlParams,
    useMultiFacetedFacets: true,
    pageSize: 0,
    page: 1,
    facets:
      'countrySubdivision,make,makeModel,class,country,countryRegion,countryCity,fuelType,hullMaterial,enginesDriveType,numberOfEngines'
  };

  if (urlParams.city && urlParams.city.split(',').length === 1) {
    params.facets += ',cityPostalCode';
  }

  dispatch(getFacetRequest(params));

  return httpClient
    .getHttpClient()
    .get(search, { params })
    .then((res) => dispatch(getFacetSuccess(res.data)))
    .catch((err) => {
      const errorMessage = get(err, 'response.data', '').toString();
      dispatch(getDataFailure(errorMessage, get(err, 'response.status', 500)));
    });
};

export const getEnginesFacets = (url, otherParams) => async (dispatch) => {
  const search = '/search/engine';
  const currency = get(getConfig().currency, 'abbr');
  let urlParams;

  if (otherParams && otherParams.ownerDetails) {
    urlParams = parseEnginesSearchParams(url, otherParams.ownerDetails);
  } else {
    urlParams = parseEnginesSearchParams(url);
  }

  if (urlParams.category) {
    urlParams.category =
      urlParams.category === 'outboard'
        ? 'outboard,outboard-4s,outboard-2s'
        : urlParams.category;
  }

  let params = {
    pageSize: 28,
    page: 1,
    currency: currency,
    enableSponsoredSearch: true,
    ...otherParams,
    ...urlParams
  };

  dispatch(getFacetRequest(params));

  return httpClient
    .getHttpClient()
    .get(search, { params })
    .then((res) => dispatch(getFacetSuccess(res.data)))
    .catch((err) => {
      dispatch(getDataFailure(err.toString()));
    });
};

const dispatchBoatDataError = (error, dispatch) => {
  const { statusCode, errorMessage, data } = parseBoatDataError(error);
  if (statusCode === 301 && data) {
    dispatch(getDataSuccess(data, statusCode));
  } else {
    dispatch(getDataFailure( errorMessage ? errorMessage : error.toString(), statusCode ));
  }
};

export const getBoatData = (url, cookies, abContext, requestContext) => async (dispatch) => {
  let boatParams = parseBoatDetailParams(url);
  const config = getConfig();
  dispatch(getDataRequest(boatParams));
  const {boatDetails, error} = await getBoatDetails(boatParams.id, config, cookies, abContext, requestContext);
  if (boatDetails) {
    dispatch(getDataSuccess(boatDetails));
  }
  if (error) {
    dispatchBoatDataError(error, dispatch);
  }
};

export const getEngineData = (url) => async (dispatch, getState, http) => {
  let boatParams = parseEngineDetailParams(url);
  const locale = getCurrentLocale(true);
  let engineListing = `/engine/${boatParams.id}?otherDealerEngines=true&locale=${locale}`;
  let apiClient = http || httpClient.getHttpClient();
  dispatch(getDataRequest(boatParams));
  const data = apiClient
    .get(engineListing)
    .then((res) => dispatch(getDataSuccess(res.data)))
    .catch((err) => {
      let statusCode = get(
        err,
        'statusCode',
        get(err, 'response.status', get(err, 'status', 500))
      );
      // NOTE: If the engine is not active by any reason, api-node-platform returns an enum status value (not numeric), but
      //       removes the html response, so if the status is not numeric we should redirecto to the SRP.
      statusCode = isNaN(statusCode) ? 301 : statusCode;
      const data = get(err, 'data', err);
      if (statusCode === 301 && data) {
        dispatch(getDataSuccess(data, statusCode));
      } else {
        const errorMessage = get(err, 'response.data', '').toString();
        dispatch(getDataFailure(errorMessage, statusCode));
      }
    });
  return data;
};

export const getEditorialContent =
  (url) => async (dispatch, getState, http) => {
    // Need to remove the language folder as it will get to the editorial API and will mess the URL for the desired article.
    const permalink = `${url}/`.replace(/^\/[a-z]{2}\//, '/'); // Add trailing slash to URL so it matches the exact permalink value
    const queryParams = `permalink=${encodeURIComponent(permalink)}`;
    const locale = `locale=${getCurrentLocale(true)}`;
    const editorialLink = `/editorial/articles?${queryParams}&${locale}`;

    const apiClient = http || httpClient.getHttpClient();
    dispatch(getDataRequest(editorialLink));
    try {
      const res = await apiClient.get(editorialLink);
      return dispatch(getDataSuccess(res.data));
    } catch (err) {
      const statusCode = get(err, 'statusCode', get(err, 'response.status', get(err, 'status', 500)));
      const errorMessage = get(err, 'response.data', '').toString();
      return dispatch(getDataFailure(errorMessage, statusCode));
    }
  };

export const getRedirectData =
  (url, search = '', subdomain = '') =>
    async (dispatch, getState, http) => {
      let apiClient = http || httpClient.getHttpClient();

      const queryParams = [];
      if (subdomain) {
        queryParams.push(`subdomain=${subdomain}`);
      }
      const transformedSearch = search.replace(/%20/g, "+");

      const legacy =
        '/legacy/redirect/' +
        encodeURIComponent(`${url}${transformedSearch}`) +
        (queryParams.length ? `?${queryParams.join('&')}` : '');

      dispatch(getDataRequest(legacy));
      try {
        const res = await apiClient.get(legacy);
        return dispatch(
          getDataSuccess({
            redirectTo: get(res.data, 'destination', undefined)
          })
        );
      } catch (error) {
        const statusCode = get(
          error,
          'statusCode',
          get(error, 'response.status', 500)
        );
        const message = error.toString();
        return dispatch(getDataFailure(message, statusCode));
      }
    };

    export const getDefaultRoutingRules = (configKey, configContext) => async (dispatch, getState, http) => {
      const configBlocks = configContext?.pages?.blocks || {};
      const configPage = configBlocks?.[configKey] || {};

      if (Object.keys(configPage).length === 0) {
        return dispatch(getStaticRoutingFailure(`pages.${configKey} does not exist in config`));
      }

      const code = configBlocks?.defaultStaticRouter;
      if (!code) {
        return dispatch(getStaticRoutingFailure(`pages.blocks.defaultStaticRouter is empty in config`));
      }

      const locale = getContentLocale();
      const path = `/blocks?contentType=${configPage.contentType}&code=${code}&locale=${locale}`;
      const apiClient = http || httpClient.getHttpClient();

      try {
        const res = await apiClient.get(path);
        return dispatch(getStaticRoutingSuccess(res.data, res.status));
      } catch (err) {
        const statusCode = err?.response?.status || err?.statusCode || 500;
        const data = get(err, 'response.data');

        if (statusCode === 301 && data) {
          return dispatch(getStaticRoutingSuccess(data, statusCode));
        }
        return dispatch(getStaticRoutingFailure(err.toString(), statusCode));
      }
    };

    const fileExtensionsRegex = /\.[a-z0-9]+$/i;
    export const getStaticContent = (location, currentSubdomain) =>
      async (dispatch, getState) => {
        const cfg = getConfig();
        const { pathname, search } = location;
        const isFile = fileExtensionsRegex.test(pathname);

        const actions = [
          dispatch(getEditorialContent(pathname))
        ];

        const locale = getContentLocale();
        const excludedBlocksLocales = ['da-DK', 'fi-FI', 'no-NO', 'se-SE'];
        const isLocaleExcluded = excludedBlocksLocales.includes(locale);
        const enableBlocksContent = !!cfg?.supports?.enableBlocksContent;
        if (enableBlocksContent && !isFile && !isLocaleExcluded) {
          // check if default static routing is already fetched
          const isDefaultStaticRouting = getState()?.app?.defaultStaticRouting?.data;
          if (!isDefaultStaticRouting) {
            actions.push(dispatch(getDefaultRoutingRules('static', cfg)));
          }
        }

        const [editorialResponse, defaultRoutingResponse] = await Promise.allSettled(actions);

        const {
          errors: editorialError,
          statusCode: editorialStatusCode
        } = editorialResponse.value;

        // if editorial exists or an error is not 404 then we don't look for another content
        if (!(editorialError && editorialStatusCode === 404)) {
          return;
        }

        // editorial not found look for redirects if enabled
        const lookupRedirects = !!cfg?.supports?.lookupGenericRedirects;
        if (lookupRedirects) {
          const {
            errors: redirectError,
            statusCode: redirectStatusCode
          } = await dispatch(getRedirectData(pathname, search, currentSubdomain));

          // if redirect exists or an error is not 404 then we don't look for another content
          if (!(redirectError && redirectStatusCode === 404)) {
            return;
          }
        }

        if (!enableBlocksContent || isFile || isLocaleExcluded) {
          return;
        }
        // if editorial and redirect not found look for default static routing content
        const defaultStaticRouting = getState()?.app?.defaultStaticRouting;
        const { errors: defaultRoutingError } = defaultRoutingResponse?.value || {};

        if (defaultRoutingError || !defaultStaticRouting?.data?.length) {
          return;
        }

        // if current path matches with any of the static routing path then get the content blocks
        const matchingRoutes = defaultStaticRouting.data.filter(({ path }) => path === pathname);
        if (matchingRoutes.length === 0) {
          return;
        }

        const [firstMatch] = matchingRoutes;
        await dispatch(setStaticRoutingMatch(firstMatch));
        const { dataBlock: code } = firstMatch;
        return dispatch(getContentBlocksData(code, 'static', false));
      };

export const getBlogContent = (url, cookies, otherParams, abTestContext) => async (dispatch, getState, http) => {
  const blogConfig = get(getConfig().pages, 'blog', {});
  const pageSize = get(blogConfig.pagination, 'pageSize', 9);
  const urlParams = parseBlogParams(url);
  const params = {
    maxRelatedArticles: BLOG_RELATED_ARTICLES_MAX,
    type: BLOG_ARTICLE_TYPE,
    locale: getCurrentLocale(true),
    pageSize: pageSize,
    page: 1,
    seoItems: 'seo-meta-tags',
    ...urlParams
  };
  const blog = '/editorial/articles';
  const pageContext = {abTestContext};
  const multiAdsData = await getMultiAds(pageContext, true, cookies, otherParams, null, null, null, AD_PAGE_KEY.BLOG, 'getBlogContent');

  let apiClient = http || httpClient.getHttpClient();

  dispatch(getDataRequest(params));
  return  apiClient
    .get(blog, { params })
    .then((res) => {
      dispatch(getDataSuccess(res.data));
      dispatch(postAdsSuccess(multiAdsData));
    })
    .catch((err) => {
      const statusCode = get(err, 'response.status', 500);
      const errorMessage = get(err, 'response.data', '').toString();
      dispatch(getDataFailure(errorMessage,  statusCode));
    });
};

export const carouselButtonClick = (carouselClickCount) => {
  return {
    type: types.INCREMENT_CAROUSEL_CLICKCOUNT,
    carouselClickCount: carouselClickCount
  };
};

export const setIsWorking = (value) => ({
  type: types.SET_IS_WORKING,
  data: value
});

export const setPartyRecords = (data) => ({
  type: types.SET_PARTY_RESULTS,
  data
});

export const setPartyResultsError = () => ({
  type: types.SET_PARTY_RESULTS_ERROR
});

export const setPartySearchParams = (params) => ({
  type: types.SET_PARTY_SEARCH_PARAMS,
  data: params
});

export const setPartySearchLocationSuggestions = (data) => ({
  type: types.SET_PARTY_SEARCH_AUTO_SUGGESTIONS,
  data
});

export const setPartySearchAutocompleteText = (data) => ({
  type: types.SET_PARTY_SEARCH_AUTO_TEXT,
  data
});

const setAutocompleteText = () => async (dispatch, getState, http) => {
  const params = get(getState(), 'app.parties.search.params', {});

  if (!params.location) {
    return;
  }

  const url = `/location/${params.location}`;
  const apiClient = http || httpClient.getHttpClient();

  try {
    const response = await apiClient.get(url);
    if (response.data) {
      const text = ['locality', 'region2', 'region1', 'iso', 'postcode']
        .map((key) => response.data[key])
        .filter((value) => value && value !== '-')
        .join(', ');
      dispatch(setPartySearchAutocompleteText(text));
    }
  } catch (e) {
    dispatch(setPartySearchAutocompleteText(params.location));
  }
};

export const getPartyResults =
  (params = {}, options = {}) =>
    async (dispatch, getState, http) => {
      const isLoading = get(getState(), 'app.isWorking');

      if (isLoading) {
        return;
      }

      dispatch(setIsWorking(true));

      params.locale = getCurrentLocale(true);

      const apiParams = omitBy(params, (value) => !value);

      dispatch(setPartySearchParams(apiParams));

      const apiClient = http || httpClient.getHttpClient();
      const url = '/search/party';

      try {
        const response = await apiClient.get(url, {
          params: apiParams
        });

        dispatch(setPartyRecords(get(response, 'data', {})));
      } catch {
        dispatch(setPartyResultsError());
      }

      dispatch(setIsWorking(false));

      if (options.updateLocationText) {
        await dispatch(setAutocompleteText());
      }
    };

let autoCompleteCalls = 0;

export const getLocationSuggestions =
  (text) => async (dispatch, getState, http) => {
    const callId = ++autoCompleteCalls;
    const apiClient = http || httpClient.getHttpClient();
    const url = '/location/suggestion';
    const params = { keyword: text };
    try {
      const response = await apiClient.get(url, { params });
      if (callId >= autoCompleteCalls) {
        // only takes the latest call to the server
        const data = get(response, 'data', []);
        dispatch(setPartySearchLocationSuggestions(data));
      }
    } catch {
      // do nothing since this is just for autocompletion
    }
  };

export const setConfigGeoRegion = (data) => ({
  type: types.SET_CONFIG_GEO_REGION,
  data
});

export const getConfigGeoRegion = () => async (dispatch, getState, http) => {
  const region = get(getState(), 'app.config.geo.region');

  if (!isEmpty(region)) {
    return;
  }

  const apiClient = http || httpClient.getHttpClient();
  const url = '/config/geo/region';
  try {
    const response = await apiClient.get(url, {});
    dispatch(setConfigGeoRegion(get(response, 'data', {})));
  } catch (error) {
    const errorMessage = get(error, 'response.data', '').toString();
    dispatch(getDataFailure(errorMessage, get(error, 'statusCode', 500)));
  }
};

export const getHomeData = (otherParams = {}, cookies, requestContext) => async (dispatch) => {
  const search = '/home';
  const language = getCurrentLocale();
  const cfg = getConfig();
  const cfgLangs = cfg.languages[language];
  const enableHomeBlogSection = getConfigParamForLanguage('supportsBlogHome', false);

  const enablePrivateFeatured = cfg?.supports?.enablePrivateFeatured;
  const selectedLocale = cfgLangs.apiLocale || getCurrentLocale(true);
  const currency = getCurrentCurrency();
  const otherParamsWithoutTestId = omit(otherParams, 'testId');
  const params = {
    selectedLocale, enableHomeBlogSection, currency: currency?.currency?.code, ...otherParamsWithoutTestId
  };
  const pageContext = {requestContext};
  const multiAdsData = await getMultiAds(pageContext, true, cookies, otherParams, null, null, null, AD_PAGE_KEY.HOME, 'getHomeData');

  params.adType = 'featured_boat_rotational';

  if (enablePrivateFeatured) {
    params.enablePrivateFeatured = enablePrivateFeatured;
  }

  const apiClient = httpClient.getHttpClient();

  dispatch(getDataRequest(params));
  return apiClient
    .get(search, { params })
    .then((res) => {
      dispatch(getDataSuccess(res?.data));
      dispatch(postAdsSuccess(multiAdsData));
    })
    .catch((err) => {
      const errorMessage = get(err, 'response.data', '').toString();
      dispatch(getDataFailure(errorMessage, get(err, 'response.status', 500)));
    });
};

export const getRedirectPathName =
  (location, match) => async (dispatch, getState, http, i18nService) => {
    const pathname = location.pathname;
    const search = location.search;
    const facetsMap = get(getConfig().redirects, 'facetsMap', {});
    const redirectsMaps = get(getConfig().redirects, 'redirectsMaps', []);
    const matchedParams = match.params;
    const mappedValues = getMappedValues(matchedParams, facetsMap);
    const redirectMap = redirectsMaps.find((redirectMap) =>
      matchPath(pathname, { path: redirectMap.origins, exact: true })
    );
    if (
      redirectMap &&
      (redirectMap.bdp || redirectMap.dealer || redirectMap.edp)
    ) {
      const legacyId = mappedValues.id;
      let pageType = 'bdp';
      if (redirectMap.dealer) {
        pageType = 'dealer';
      } else if (redirectMap.edp) {
        pageType = 'edp';
      }
      let legacy = `/legacy/${pageType}/${legacyId}`;
      if (
        redirectMap.lookupType &&
        (redirectMap.alias || redirectMap.connectionValue)
      ) {
        legacy += `?lookupType=${redirectMap.lookupType}`;
        if (redirectMap.alias) {
          legacy += `&alias=${redirectMap.alias}`;
        }
        if (redirectMap.connectionValue) {
          legacy += `&connectionValue=${redirectMap.connectionValue}`;
        }
      }
      let apiClient = http || httpClient.getHttpClient();
      dispatch(getDataRequest());
      const data = apiClient
        .get(legacy)
        .then((res) => {
          if (!redirectMap.dealer) {
            mapRedirectResultsToValues(mappedValues, res.data);
          }
          return getDestinationPath(
            redirectsMaps,
            pathname,
            search,
            mappedValues,
            res.data
          );
        })
        .then((redirectTo) => {
          dispatch(getDataSuccess({ redirectTo }));
        })
        .catch(() => {
          const routeConstants = getRouteConstantsFromI18n(i18nService);
          const destPathname = redirectMap.dealer
            ? `${routeConstants.PARTY_SEARCH_URL_ROOT}`
            : redirectMap.edp
              ? `${routeConstants.ENGINES_SEARCH_URL_ROOT}`
              : `${routeConstants.SEARCH_URL_ROOT}`;
          dispatch(getDataSuccess({ redirectTo: { destPathname } }));
        });
      return data;
    }
    // If is a normal redirect...
    const redirectTo = await getDestinationPath(
      redirectsMaps,
      pathname,
      search,
      mappedValues
    );
    dispatch(getDataSuccess({ redirectTo }));
    return;
  };

export const createSubscription =
  (email) => async (dispatch, getState, http) => {
    const subscribe = '/marketing/subscriber';
    const body = {
      email
    };
    let apiClient = http || httpClient.getHttpClient();
    dispatch(postDataRequest());
    const data = apiClient
      .post(subscribe, body)
      .then((res) => {
        dispatch(postDataSuccess(res.status));
      })
      .catch((err) => {
        const errorMessage = get(err, 'response.data', '').toString();
        dispatch(postDataFailure(errorMessage, get(err, 'response.status', 500)));
      });
    return data;
  };

export const createSearchAlert =
  ({ userEmail, searchParams, searchUrl, criteria }, postSuccessCallback) =>
    async (dispatch, getState, http) => {
      const search = '/pbs/save';

      const body = {
        'username': userEmail,
        'searchParams': searchParams,
        'searchUrl': searchUrl,
        'criteria': criteria,
        'locale': getCurrentLocale(true)
      };

      let apiClient = http || httpClient.getHttpClient();
      dispatch(postDataRequest());
      const data = apiClient
        .post(search, body)
        .then((res) => {
          postSuccessCallback();
          dispatch(postDataSuccess(res.status));
        })
        .catch((err) => {
          const errorMessage = get(err, 'response.data', '').toString();
          dispatch(postDataFailure(errorMessage, get(err, 'response.status', 500)));
        });

      return data;
    };

export const getRelatedListings = (params) => {
  const search = '/search/boat/related';
  return httpClient.getHttpClient().get(search, { params });
};

export const showAdditionalLeadsModal =
  (leadData) => async (dispatch, getState) => {
    try {
      const listing = get(getState(), 'app.data');
      let paramList = get(getConfig(), 'pages.details.additionalLeads.params', [
        'location',
        'make',
        'model',
        'class'
      ]);
      let params = buildRelatedListingParamsFromListing(
        getConfig(),
        paramList,
        listing
      );
      let relatedListings = await getRelatedListings(params);
      if (relatedListings.data) {
        while (relatedListings.data.length < 3 && paramList.length > 0) {
          paramList = paramList.pop();
          params = buildRelatedListingParamsFromListing(
            getConfig(),
            paramList,
            listing
          );
          relatedListings = await getRelatedListings(params);
        }
        const data = {
          leadData,
          listings: get(relatedListings, 'data', {})
        };
        dispatch(setAdditionalLeadsData(data));
        dispatch(openAdditionalLeadsModal());
      } else {
        dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
        dispatch(closeAdditionalLeadsModal());
      }
    } catch (e) {
      dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
      dispatch(closeAdditionalLeadsModal());
    }
  };

export const getSimilarListingsData = (leadData) => async (dispatch, getState) => {
  try {
    const listing = getState()?.app?.data;
    const paramList = getConfig()?.pages?.details?.additionalLeads?.params || ['location', 'make', 'model', 'class'];
    const relatedListings = await fetchRelatedListings(paramList, listing);
    dispatch(setAdditionalLeadsData({ leadData, listings: relatedListings }));
  } catch (e) {
    dispatch(setAdditionalLeadsData({ leadData: {}, listings: [] }));
  }
};

const fetchRelatedListings = async (paramList, listing) => {
  let params;
  let relatedListings = [];
  for (let i = paramList.length; i > 0 && relatedListings.length < 3; i--) {
    params = buildRelatedListingParamsFromListing(getConfig(), paramList.slice(0, i), listing);
    relatedListings = await getRelatedListings(params);
  }
  return relatedListings?.data || [];
};


export const setAdditionalLeadsData = (data) => ({
  type: types.SET_ADDITIONAL_LEADS_DATA,
  data
});

export const openAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: true
});

export const closeAdditionalLeadsModal = () => ({
  type: types.TOGGLE_ADDITIONAL_LEADS_MODAL,
  data: false
});

let autoCompleteMakeModelCalls = 0;
export const getMakeModelTypeahead =
  (text, extraParams = []) =>
    async (dispatch, getState, http) => {
      const callId = ++autoCompleteMakeModelCalls;
      const url = `/make-model/suggestion?keyword=${text}${extraParams.length ? '&' + extraParams.join('&') : ''
      }`;
      const apiClient = http || httpClient.getHttpClient();
      try {
        const response = await apiClient.get(url);
        if (callId >= autoCompleteMakeModelCalls) {
          // only takes the latest call to the server
          const data = get(response, 'data', []);
          dispatch(setMakeModelSearchSuggestions(data));
        }
      } catch {
        // Do nothing since this is just for autocompletion
      }
    };

export const setMakeModelSearchSuggestions = (data) => ({
  type: types.SET_MAKEMODEL_SEARCH_AUTO_SUGGESTIONS,
  data
});

export const getSbpData =
  (params, imtID, currentSbpListings = [], cookies, requestContext) => async (dispatch, getState, http) => {
    const apiClient = http || httpClient.getHttpClient();
    const endPoint = `/search/boat/related/${imtID}`;

    try {
      dispatch(getDataRequest(params));
      const res = await apiClient.get(endPoint, {
        params: { ...params, pageSize: 27 },
        headers: { 'x-type-portal': types.HEADER_PORTAL_TYPE }
      });
      const firstSbpListing = res.data?.[0] || {};
      const pageContext = {requestContext};
      const multiAdsData = await getMultiAds(
        pageContext,
        true,
        cookies,
        null,
        null,
        null,
        firstSbpListing,
        AD_PAGE_KEY.SBP,
        'getSbpData'
      );
      dispatch(getDataSuccess({ sbpListings: [...currentSbpListings, ...res.data] }));
      dispatch(postAdsSuccess(multiAdsData));
    } catch (err) {
      const statusCode = get(err, 'statusCode', '');
      const errorMesssage = get(err, 'message');
      const data = get(err, 'response.data');
      if (statusCode === 301 && data) {
        return dispatch(getDataSuccess(data, statusCode));
      }
      return dispatch(getDataFailure(errorMesssage, statusCode));
    }
  };

export const getContentBlocksData = (pageKeyPath, configKey, prefix = null, customLocale = null) => async (dispatch, getState, http) => {
  const configBlocks = get(getConfig().pages, 'blocks', {});
  const configPage = get(configBlocks, configKey, {});

  if (Object.keys(configPage).length === 0) {
    return dispatch(getDataFailure(`pages.${configKey} does not exist in config`));
  }

  const pageKeyPrefix = prefix === false ? '' : ( prefix || configBlocks.pageKeyPrefix );
  const code = pageKeyPrefix + pageKeyPath;
  const locale = customLocale || getContentLocale();
  const path = `/blocks?contentType=${configPage.contentType}&code=${code}&locale=${locale}`;
  let apiClient = http || httpClient.getHttpClient();

  try {
    dispatch(setUserAgent(getUserAgent()));
    dispatch(getDataRequest(configBlocks));
    const res = await apiClient.get(path);
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    const statusCode = err?.response?.status || err?.statusCode || 500;
    const data = get(err, 'response.data');

    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};

export const getAds = (cookies) => async (dispatch, getState, http) => {
  const apiClient = http || httpClient.getHttpClient(getHttpRequest()?.headers);

  // TODO: Remove these hardcoded params and use parameters received in the function:
  // export const getAds = (params, ...) => async (dispatch, getState, http) => {
  const params = {
    zoneId: 1,
    responseType: 'json',
    size: '300x250'
  };
  const hasConsent = hasAdvertisingConsent(cookies?.cookies);
  const path = `/serve-ad?zoneID=${params.zoneId}&responseType=${params.responseType}&size=${params.size}&hasTargetedAdsConsent=${hasConsent}`;
  try {
    dispatch(setUserAgent(getUserAgent()));
    dispatch(getDataRequest(params));
    const res = await apiClient.get(path);
    return dispatch(getDataSuccess(res.data));
  } catch (err) {
    const statusCode = err?.response?.status || err?.statusCode || 500;
    const data = get(err, 'response.data');
    if (statusCode === 301 && data) {
      return dispatch(getDataSuccess(data, statusCode));
    }
    return dispatch(getDataFailure(err.toString(), statusCode));
  }
};
